.video-container {
    width: 100%;
    height: auto;
    display: grid;
    margin-bottom: 1rem;
  }
  
  .video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    width: 100%;
  }
  
  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .video {
    width: 100%;
    height: 100%;
  }
  
  .video-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
  }
  
  .profile-icon {
    margin-right: 2ch;
  }
  
  .video-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 48px;
    background-color: transparent;
  }

  .video-thumbnail-wrapper {
    position: relative;
    display: inline-block; /* Or another display type that suits your layout */
  }
  .video-username-wrapper {
    display: flex;
    align-items: center;
    margin-left: 48px;
  }

  .video-thumbnail-wrapper:hover {
    cursor: pointer;
  }

  .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-70%, -60%);
    font-size: 5ch; /* Adjust size as needed */
    color: #FE2C55; /* Adjust color as needed */
    /* Additional styling for the play icon as needed */
  }

  .play-icon:hover {
    cursor: pointer;
  }
  
  .video-title {
    font-size: 2ch;
    font-weight: bold;
    margin: 0;
  }
  
  .video-username {
    font-size: 1.8ch;
    color: black;
    margin: 0;
  }
  
  .video-share-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }
  
  .video-shares,
  .video-cost, .video-views, .video-updated-timestamp {
    font-size: 1.65ch;
    margin-left: 1ch;
    margin-right: 1ch;

  }

  .video-info-avatar {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .video-share-info {
    display:flex;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
  }
