.investment-cta {
    display: flex;
    justify-content: center;
    align-items: center;
}

.investment-cta button {
    padding: 10px 24px;
    font-weight: 600;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: var(--primary-color, #007bff);
    color: white;
    text-transform: uppercase;
}

.investment-cta button:hover {
    transform: translateY(-0px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: var(--primary-color-dark, #0056b3);
}

.investment-cta button:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
} 