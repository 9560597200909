.video-details-container {
    margin: 0 auto;
    max-width: 2400px;
    display: grid;
    grid-template-columns: minmax(0, 70%) minmax(0, 20%);
    gap: 15ch;
    padding: 20px 24px;
    margin-left: 100px;
  }
  
  .video-metadata {
    margin-bottom: 10px;
  }

  
  .video-stream {
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    margin-bottom: 16px;
  }

  .related-videos, .explore-more-video {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }
  
  .related-video, .explore-more-video {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 1rem;
    text-align: center;
    cursor: pointer;
  }
  
  .related-video-thumbnail, .explore-more-video-thumbnail {
    width: 100%;
    height: auto;
  }
  
  .related-video-title, .explore-more-video-title {
    margin-top: 0.5rem;
    font-size: 1rem;
  }

  .full-width-video {
    width: 100%;
    height: 100%;
  }

  .comments-and-related-videos {
    display: block;
    grid-column: 1;
    margin-top: 2ch;
  }
  
  .video-comments {
    width: 100%;
    margin-right: 0;
  }
  
.comment-array {
  margin-top: 3ch;
}
.related-videos {
  grid-column: 2;
  padding: 0 12px;
  margin-top: 0;
  min-width: 0;
  max-height: calc(100vh - 0px);
  overflow-y: auto;
  position: sticky;
  top: 20px;
}


  .video-stats {
    margin: 16px 0;
  }

  .video-stats table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ccc; /* Add border to the table */
  }
  
  .video-stats th,
  .video-stats td {
    border: 1px solid #ccc; /* Add border to table cells */
    padding: 8px;
    text-align: left;
  }
  
  /* Style the first row (thead) with a different background color */
  .video-stats th {
    background-color: #f2f2f2;
  }

  .video-engagement-buttons {
    display: flex;
    gap: 8px;
    margin: 8px 0;
    padding: 0;
  }

  .video-engagement-buttons button {
    padding: 8px 16px;
    border: none;
    border-radius: 18px;
    background-color: #f2f2f2;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .video-engagement-buttons button:hover {
    background-color: #e5e5e5;
  }

  .video-purchase-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .video-purchase-buttons button {
    margin-top: 1ch;
    margin-right: 1ch;
  }



  .video-details-summary {
    display: flex;
    align-items: center;
    padding: 12px 0;
    gap: 12px;
    margin: 0;
  }

  .video-details-summary .profile-icon {
    width: 40px;
    height: 40px;
  }

  .video-details-summary p {
    margin: 0;
    color: #606060;
    font-size: 14px;
  }

  .investment-cta {
    margin: 0px 0;
    padding: 0 12px;
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .investment-cta h2 {
    font-size: 22px;
    font-weight: 600;
    margin: 0;
    flex: 1;
  }

  .investment-cta button:hover {
    cursor: pointer;
    background-color: #ff4466; /* Darken the color on hover */

  }
  
  .investment-cta p {
    font-weight: bold; /* Bold the font */
  }
  
  .investment-cta button {
    height: 4ch;
    width: 15ch;
    padding: 0 10px; /* Adjust padding for standard button size */
    background-color: #FE2C55;
    color: white;
    border: none;
    margin-left: 5ch;
    font-weight: bold;
    font-size: 1.5ch;
    border-radius: 1ch;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Add transition for hover effect */
  }

  .video-description {
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid #e0e0e0;
    font-size: 14px;
    line-height: 1.4;
    color: #0f0f0f;
  }

  /* Update related videos section */
  .related-videos {
    grid-column: 2;
    padding: 0 8px;
    margin-top: 0;
    min-width: 0;
    max-height: calc(100vh - 40px);
    overflow-y: auto;
  }

  /* New styles for related video items */
  .related-video {
    display: flex;
    gap: 12px;
    margin-bottom: 16px;
    padding: 0;
    border: none;
    text-align: left;
    cursor: pointer;
    width: 100%;
    text-decoration: none;
  }

  .related-video-thumbnail {
    width: 168px;
    height: 94px;
    object-fit: cover;
    border-radius: 8px;
    flex-shrink: 0;
  }

  .related-video-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
    min-width: 0; /* Prevents text overflow */
  }

  .related-video-title {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    color: #0f0f0f;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .related-video-metadata {
    display: flex;
    flex-direction: column;
    gap: 2px;
    color: #606060;
    font-size: 12px;
  }

  .related-video-creator {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .related-video-stats {
    display: flex;
    gap: 4px;
    align-items: center;
  }

  .related-video-stats span::after {
    content: "•";
    margin-left: 4px;
  }

  .related-video-stats span:last-child::after {
    content: "";
  }

  /* Remove old styles */
  .explore-more-video {
    display: none;
  }

  /* Add media queries for different screen sizes */
  @media (max-width: 1400px) {
    .video-details-container {
      max-width: 1400px;
      grid-template-columns: minmax(0, 70%) minmax(0, 30%);
    }
    
    .related-video-thumbnail {
      width: 140px;
      height: 79px;
    }
  }

  @media (max-width: 1200px) {
    .video-details-container {
      max-width: 1200px;
      grid-template-columns: minmax(0, 65%) minmax(0, 35%);
    }
    
    .related-video-thumbnail {
      width: 120px;
      height: 68px;
    }
  }

  @media (max-width: 768px) {
    .video-details-container {
      grid-template-columns: 1fr;
      margin-left: 80px;
      padding-left: 20px;
    }

    .related-videos {
      grid-column: 1;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 16px;
      padding: 16px 0;
    }
  }
  