.signup-container {
  max-width: 400px;
  margin: 40px auto;
  padding: 20px;
  text-align: center;
}

.auth-options {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 30px 0;
}

.auth-options button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
  border: 1px solid #ddd;
  background: white;
  transition: background-color 0.2s;
}

.auth-options button img {
  width: 20px;
  height: 20px;
}

.google-auth {
  background: white;
  color: #000;
}

.google-auth:hover {
  background: #f8f9fa;
}

.facebook-auth {
  background: #1877f2;
  /* color: white; */
  color: 1877f2;
  border: none;
}

.facebook-auth:hover {
  background: #166fe5;
  color: white;
}

.email-auth {
  background: white;
  color: #000;
}

.email-auth:hover {
  background: #f8f9fa;
}

.divider {
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.divider::before,
.divider::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 45%;
  height: 1px;
  background: #ddd;
}

.divider::before {
  left: 0;
}

.divider::after {
  right: 0;
}

.divider span {
  background: white;
  padding: 0 10px;
  color: #666;
}

.signin-prompt {
  margin-top: 20px;
  color: #666;
}

.signin-prompt button {
  color: #FE2C55;
  background: none;
  border: none;
  font-weight: 600;
  cursor: pointer;
  padding: 0 5px;
}

.signin-prompt button:hover {
  text-decoration: underline;
} 