.channel-settings-container {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
}

.channel-settings-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.profile-image-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.profile-preview {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #eee;
}

.hidden {
  display: none;
}

.upload-button {
  background: #f0f0f0;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.upload-button:hover {
  background: #e0e0e0;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  font-weight: 500;
  color: #333;
}

.form-group input {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.handle-input {
  display: flex;
  align-items: center;
  gap: 4px;
}

.handle-input span {
  color: #666;
  font-size: 16px;
}

.handle-input input {
  flex: 1;
}

.save-button {
  background: #FE2C55;
  color: white;
  border: none;
  padding: 14px;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
}

.save-button:hover {
  background: #e62548;
}

.error-message {
  background: #ffebee;
  color: #c62828;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 20px;
} 