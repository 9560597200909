.payment-form {
  padding: 32px;
  min-width: 400px;
  background: white;
  border-radius: 8px;
}

.payment-form h3 {
  margin: 0 0 24px 0;
  color: #32325d;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}

.card-element-container {
  padding: 16px;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  background: #f8f9fa;
  margin: 20px 0;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15);
  transition: box-shadow 0.3s ease;
}

.card-element-container:hover {
  box-shadow: 0 2px 5px rgba(50, 50, 93, 0.2);
}

.submit-button {
  width: 100%;
  padding: 14px;
  background: #5469d4;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 20px;
}

.submit-button:hover {
  background: #4054b2;
  transform: translateY(-1px);
  box-shadow: 0 2px 5px rgba(50, 50, 93, 0.1);
}

.submit-button:disabled {
  background: #93a3e8;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.error-message {
  color: #dc3545;
  background: #fff5f5;
  padding: 12px;
  border-radius: 4px;
  font-size: 14px;
  margin: 10px 0;
  border: 1px solid #ffebee;
}

/* Add loading spinner */
.loading-spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255,255,255,.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s ease-in-out infinite;
  margin-right: 8px;
}

@keyframes spin {
  to { transform: rotate(360deg); }
} 