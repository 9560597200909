  .dropdown {
    background-color: white;
    border: 1px solid black;
    width: 200px;
    padding: 10px;
  }

  .dropdown-item {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .dropdown-item:hover {
    cursor: pointer;
  }

  .video-call-btn {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
  
  .main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f8f8f8;
    padding: 10px 0;
    padding-left: 80px; /* Add this line to account for the width of the side menu */
    position: relative; /* Add this line to make .dropdown-container position relative to this container */
  }
  
  .search {
    display: flex;
    align-items: center;
    flex-grow: 0.6; /* Decrease this value to make the search bar take less space */
    margin-left: 10px;
  }
  
  .search-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f8f8; /* Same background color as the main div */
    margin-left: 5px;
    padding: 5px 8px;
    border: 1px solid rgba(0, 0, 0, 0.23); /* Same border style as the TextField */
    border-radius: 4px; /* Same border radius as the TextField */
  }
  
  .searchbar-container {
    position: relative;
  }
  
  .search-icon {
    cursor: pointer;
  }
  
  .icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 5ch;
    margin: 2ch;
  }
  
  .icons svg {
    margin-left: 10px;
    cursor: pointer;
    width: 4ch; /* Increase this value to make the icons larger */
    height: 4ch; /* Increase this value to make the icons larger */
  }
  
  h1 {
    margin-left: 10px;
  }
  
  .hamburger-menu {
    display: flex;
    align-items: center;
    margin-left: 40px;
  }
  
  .hamburger-menu svg {
    cursor: pointer;
    font-size: 1.5rem;
    width: 40px;
    height: 40px;
  }

.sign-in-text {
    font-size: 1.3ch;
    padding: 1ch;
    border-radius: 50px; 
    transition: border-color 0.3s, color 0.3s; 
    border: 1px solid transparent; 
    /* Add any other styling you'd like */
}
  
.sign-in-container {
    display: flex;
    margin-right: 1ch;
    align-items: center;
    gap: 4px; /* Adjust the gap between the icon and the text as needed */  
}

.sign-in-container:hover {
    color: blue; 
    cursor: pointer;
    border-color: blue; 
    border: 0px solid blue;
    border-radius: 50px; 
    background-color: lightskyblue;

}
  
.shopping-cart-container, .notification-container, .videoicon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
.shopping-cart-text, .notification-text, .videoicon-text {
    position: absolute;
    top: 8ch;
    font-size: 1ch;
    max-width: 100px; /* Set the maximum width for wrapping */
    background-color: grey;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    word-wrap: break-word; /* Wrap the text */
  }
  
  .profile-image:hover {
    cursor: pointer;
  }