.comment-section {
  display: flex;
  align-items: start; /* align items to the top */
  margin-bottom: 2ch;
}

.comment-section {
  flex-grow: 1;
  margin: 0 10px;
}

.comment-section .MuiAvatar-root {
  margin-right: 10px;
}

.comment-buttons {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-left: auto;
}

.engagement-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.engagement-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.engagement-button span {
  margin-left: 5px;
}
