.wallet-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 0 auto;
    max-width: 1400px;
    width: 90%;
}

.wallet-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    padding: 1rem;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.portfolio-summary {
    display: flex;
    gap: 4rem;
}

.metric {
    text-align: left;
}

.metric-value {
    font-size: 2rem;
    font-weight: 700;
    color: #2196f3;
    margin: 0;
}

.metric-label {
    font-size: 0.9rem;
    color: #666;
    margin: 0.25rem 0 0 0;
}

.header-actions {
    display: flex;
    gap: 1rem;
}

.action-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 6px;
    background: #2196f3;
    color: white;
    font-weight: 500;
    cursor: pointer;
    transition: background 0.2s;
}

.action-button:hover {
    background: #1976d2;
}

.resources-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 600px));
    gap: 2.5rem;
    margin: 0 auto 2rem auto;
    padding: 1.5rem;
    justify-content: center;
    max-width: 1400px;
}

.resource-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.2s;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.resource-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.resource-details {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
}

.ownership-info {
    margin-bottom: 1rem;
}

.ownership-info p {
    display: flex;
    justify-content: space-between;
    margin: 0.5rem 0;
}

.label {
    color: #666;
}

.value {
    font-weight: 500;
    color: #333;
}

.card-actions {
    display: flex;
    gap: 0.5rem;
}

.resource-stats {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
}

.stat-group {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding: 0.5rem 0;
    border-bottom: 1px solid #eee;
}

.stat-group:last-child {
    border-bottom: none;
}

.stat-group span {
    font-size: 1rem;
    color: #333;
    white-space: normal;
    line-height: 1.4;
}

.trade-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    padding: 1.5rem 0;
    margin-top: auto;
    width: 100%;
    min-height: 80px;
}

.investment-cta {
    flex: 1;
    max-width: 120px;
}

.investment-cta button {
    width: 100%;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 8px;
    background-color: #ff4d4f;
    color: white;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
}

.investment-cta button:hover {
    background-color: #ff7875;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    .resources-grid {
        grid-template-columns: repeat(2, minmax(0, 500px));
        gap: 2rem;
    }
}

@media (max-width: 900px) {
    .resources-grid {
        grid-template-columns: minmax(0, 600px);
        gap: 1.5rem;
    }

    .resource-card {
        max-width: 100%;
    }

    .wallet-container {
        width: 95%;
        padding: 15px;
        margin-left: 0;
    }
}

.stripe-security-message {
    padding: 16px;
    background-color: #f8fafc;
    border-bottom: 1px solid #e5e7eb;
    display: flex;
    align-items: center;
    gap: 8px;
}

.stripe-security-message p {
    color: #64748b;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin: 0;
}

.stripe-security-message a {
    text-decoration: none;
}

.stripe-security-message a:hover {
    text-decoration: underline;
}

.trade-actions button {
    min-width: 100px;
    padding: 0.75rem 1.5rem;
    margin: 0;
}