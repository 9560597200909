.trade-modal-container {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
}

.trade-modal {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-header {
  padding: 20px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.video-info {
  display: flex;
  gap: 20px;
  align-items: center;
}

.thumbnail {
  width: 120px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
}

.video-details h2 {
  margin: 0 0 8px 0;
  font-size: 1.2rem;
}

.trade-type {
  color: #666;
  margin: 0;
}

.close-button {
  font-size: 24px;
  color: #666;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.trade-content {
  padding: 20px;
}

.order-details-section {
  margin-bottom: 24px;
}

.details-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-top: 16px;
}

.detail-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background: #f8f9fa;
  border-radius: 6px;
}

.label {
  color: #666;
  font-size: 0.9rem;
}

.value {
  font-weight: 500;
}

.trade-execution-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px;
  background: #f8f9fa;
  border-radius: 8px;
  margin-bottom: 24px;
}

.shares-input input, .order-type select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  margin-top: 8px;
}

.total-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  border-top: 1px solid #ddd;
}

.total-amount {
  font-size: 1.2rem;
  font-weight: 600;
}

.execute-button {
  background: #FE2C55;
  color: white;
  border: none;
  padding: 14px;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
}

.execute-button:hover {
  background: #e62548;
}

.disclosure {
  color: #666;
  font-size: 0.9rem;
}

.order-note {
  margin-bottom: 12px;
}

.legal {
  font-size: 0.8rem;
}

.execute-button.disabled {
  background: #cccccc;
  cursor: not-allowed;
}


.signin-button:hover {
  background: #357abd;
}

.auth-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.signup-button, .signin-button {
  flex: 1;
  padding: 14px;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
}

.signup-button {
  background: #4285f4;
  color: white;
  border: none;
}

.signup-button:hover {
  background: #357abd;
}

.signin-button {
  background: white;
  color: #4285f4;
  border: 1px solid #4285f4;
}

.signin-button:hover {
  background: #fff5f6;
}

.price-details {
  background: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  margin: 15px 0;
}

.bid-ask-spread {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  margin-bottom: 10px;
}

.bid, .ask, .spread {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.label {
  font-size: 12px;
  color: #666;
  margin-bottom: 4px;
}

.value {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.bid .value {
  color: #dc3545;  /* Red for bid */
}

.ask .value {
  color: #28a745;  /* Green for ask */
}

.execution-price {
  text-align: center;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #dee2e6;
}