  .video-list-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
    
  }

  .video-list {
    display: grid;
    margin-left: 4ch;
    grid-template-columns: repeat(3, 1fr); /* 4 items per row */
    grid-gap: 8ch; /* Spacing between grid items */
    padding: 3ch; /* Padding around the grid */
    justify-content: center; /* Center grid items horizontally */
  }
  
  .video-thumbnail-wrapper {
    width: 100%; /* Makes the thumbnail wrapper fill the grid item */
    position: relative;
    padding-top: 56.25%; /* Aspect ratio for 16:9 videos, adjust as needed */
  }
  
  .video-thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the thumbnail covers the wrapper without stretching */
  }
  

    /* Media query for tablets (i.e., iPads) and smaller screens */
@media (max-width: 1000px) {
  .video-list {
    display: grid;
    margin-left: 4ch;
    grid-template-columns: repeat(1, 1fr); /* 4 items per row */
    grid-gap: 8ch; /* Spacing between grid items */
    padding: 3ch; /* Padding around the grid */
    justify-content: center; /* Center grid items horizontally */
  }
}

/* Add to your existing CSS */
.loading-indicator {
    text-align: center;
    padding: 1rem;
    color: #666;
    font-size: 0.9rem;
}