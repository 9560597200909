.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  min-width: 500px;
}

.upload-area {
  width: 100%;
  height: 200px;
  border: 2px dashed #ccc;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  cursor: pointer;
  background-color: #fafafa;
}

.upload-area:hover {
  background-color: #f0f0f0;
  border-color: #999;
}

.upload-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 12px;
  color: #666;
}

.upload-text {
  font-size: 16px;
  color: #666;
  margin-bottom: 8px;
}

.upload-subtext {
  font-size: 13px;
  color: #999;
}

.form-fields {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

label {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

input[type="text"],
input[type="number"],
textarea {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

textarea {
  min-height: 80px;
  resize: vertical;
}

.thumbnail-preview {
  width: 100%;
  max-width: 320px;
  height: 180px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 8px;
  object-fit: cover;
}

button[type="submit"] {
  background-color: #065fd4;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 24px;
}

button[type="submit"]:hover {
  background-color: #0356c6;
}

.file-input {
  display: none;
}

.select-files-button {
  background-color: #065fd4;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 12px;
}
  