.comment-section {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
}

.comment-section form {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.comment-section input {
  width: 100%;
  padding: 8px 0;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 14px;
  background: transparent;
  outline: none;
  transition: border-bottom-color 0.2s ease;
}

.comment-section input:focus {
  border-bottom: 2px solid #065fd4;
}

.comment-form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 8px;
  animation: fadeIn 0.2s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.comment-form-buttons button {
  padding: 8px 16px;
  border: none;
  border-radius: 18px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.comment-form-buttons button[type="submit"] {
  background-color: #065fd4;
  color: white;
}

.comment-form-buttons button[type="button"] {
  background-color: transparent;
  color: #606060;
}

.comment-form-buttons button[type="button"]:hover {
  background-color: #f2f2f2;
}

.comment-form-buttons button[type="submit"]:hover {
  background-color: #0356c5;
}

.comment-form-buttons button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}