.library-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 0 auto;
    max-width: 1400px;
    width: 90%;
}

.library-content {
    margin-top: 20px;
}

.library-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.library-header h2 {
    font-size: 1.8rem;
    font-weight: 600;
    color: #333;
    margin: 0;
}

.library-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
    margin-bottom: 2rem;
    padding: 1.5rem;
}

.library-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.2s;
    min-width: 0;
}

.library-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.library-video {
    width: 100%;
    height: auto;
}

.edit-container {
    padding: 1.5rem;
}

.edit-textarea {
    width: 100%;
    min-height: 70px;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: vertical;
    font-size: 1rem;
}

.card-actions {
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
}

.action-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 6px;
    background: #2196f3;
    color: white;
    font-weight: 500;
    cursor: pointer;
    transition: background 0.2s;
}

.action-button:hover {
    background: #1976d2;
}

.pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
}

.pagination-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 6px;
    background: #f0f0f0;
    cursor: pointer;
    transition: background 0.2s;
}

.pagination-button:hover {
    background: #e0e0e0;
}

.pagination-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.page-indicator {
    padding: 0.5rem 1rem;
    color: #666;
    font-weight: 500;
}

/* Responsive adjustments */
@media (max-width: 1400px) {
    .library-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
    }
}

@media (max-width: 900px) {
    .library-grid {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }

    .library-container {
        width: 95%;
        padding: 15px;
    }
}

.channel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
}

.channel-info {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.channel-avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
}

.channel-details {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.channel-details h1 {
    margin: 0;
    font-size: 1.8rem;
    color: #333;
}

.channel-handle {
    color: #666;
    font-size: 1rem;
}

.settings-button {
    background: #FE2C55;
    color: white;
    border: none;
    padding: 14px 24px;
    border-radius: 6px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s;
    text-decoration: none;
    font-size: 1rem;
}

.settings-button:hover {
    background: #e62548;
}

@media (max-width: 768px) {
    .channel-header {
        flex-direction: column;
        gap: 1.5rem;
        padding: 1.5rem;
    }

    .channel-info {
        flex-direction: column;
        text-align: center;
    }

    .channel-details {
        align-items: center;
    }
}

