.side-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 80px;
    height: 100%;
    background-color: white;
    z-index: 10;
    padding-top: 20px;
  }
  
  .side-menu-icon {
    margin: 1ch 0;
    cursor: pointer;
    color: black;
    height: 2ch;
    width: auto;
  }
  
  .side-menu-icon:hover {
    color: #ff4081;
  }
  
  .icon-text {
    font-size:1.5ch;
    margin-top: 4px;
    text-align: center;
  }
  
  .icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px;
  }

  .icon-container:hover {
    color: #ff4081;
  }