.view-history-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-left: 100px;
    max-width: 1200px;
}

.view-history-content {
    margin-top: 20px;
}

.view-history-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.view-history-header h2 {
    font-size: 1.8rem;
    font-weight: 600;
    color: #333;
    margin: 0;
}

.view-history-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    overflow: hidden;
}

.view-history-table th,
.view-history-table td {
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid #eee;
}

.view-history-table th {
    background-color: #f8f9fa;
    font-weight: 600;
    color: #444;
}

.video-preview-cell {
    width: 180px;
    padding: 0.5rem;
}

.video-preview-cell .video-container {
    margin: 0;
    border-radius: 4px;
    overflow: hidden;
}

.video-title-link {
    color: #1a73e8;
    text-decoration: none;
    font-weight: 500;
}

.video-title-link:hover {
    text-decoration: underline;
    color: #1557b0;
}

.pagination-controls {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 2rem;
}

.pagination-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 6px;
    background: #f0f0f0;
    cursor: pointer;
    transition: background 0.2s;
}

.pagination-button:hover {
    background: #e0e0e0;
}

.pagination-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    .view-history-container {
        padding: 1rem;
    }

    .view-history-table th,
    .view-history-table td {
        padding: 0.75rem;
    }

    .video-preview-cell {
        width: 140px;
    }
}

@media (max-width: 768px) {
    .view-history-header {
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
    }

    .video-preview-cell {
        width: 120px;
    }
} 