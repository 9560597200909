.order-history-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    margin-left: 100px; /* Account for sidebar */
}

.order-history-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.back-button {
    padding: 8px 16px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.back-button:hover {
    background-color: #e0e0e0;
}

.order-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.order-table th,
.order-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
}

.order-table th {
    background-color: #f5f5f5;
    font-weight: 600;
}

.buy-order {
    color: #4CAF50;
    font-weight: 600;
}

.sell-order {
    color: #f44336;
    font-weight: 600;
}

.status-completed {
    color: #4CAF50;
}

.status-pending {
    color: #FF9800;
}

.status-failed {
    color: #f44336;
}

.order-table tr:hover {
    background-color: #f5f5f5;
}

.video-title-link {
    color: #1a73e8;
    text-decoration: none;
    font-weight: 500;
}

.video-title-link:hover {
    text-decoration: underline;
    color: #1557b0;
} 