

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #white;
  min-height: 100vh;
  color: black;
  margin-left: 40px;
  margin-right: 40px;
}

.App-link {
  color: #61dafb;
}

.creator-pic {
  border-radius: 50%;
  width:5ch;
  height: 5ch;
}

.video-details {
display: block;
}

.one-row {
  display: flex;
}

.one-row p {
  margin-left: 0.5ch;
  margin-right: 0.5ch;
  font-weight: bold;
}

.accolades p {
  background-color: black;
  color: white;
  border-radius: 15%;
  height: 2ch;
  padding: 1ch;
}

table {
  width: 100%;
}

td {
  text-align: center;
}

th {
  word-wrap: break-word;
  font-size: 10px + 2vmin;
}

img {
  width: 80%;
  height: 80%;
}

.placeholder-image {
  width: 50%;
  height: 50%;
}